import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, message } from 'antd';
import { useAuth } from '../../context/AuthContext';

const RoleManagementPage = () => {
    const [roles, setRoles] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentRole, setCurrentRole] = useState(null);
    const { makeAuthenticatedRequest } = useAuth();
    const [form] = Form.useForm();

    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const response = await makeAuthenticatedRequest('/roles', 'GET');
                setRoles(response.data);
            } catch (error) {
                message.error('Roller yüklenirken bir hata oluştu: ' + error.toString());
            }
        };
        fetchRoles();
    }, [makeAuthenticatedRequest]);

    const handleEdit = (role) => {
        setCurrentRole(role);
        setIsModalVisible(true);
        form.setFieldsValue(role);
    };

    const handleAdd = () => {
        setCurrentRole(null);
        setIsModalVisible(true);
        form.resetFields();
    };

    const handleDelete = async (roleId) => {
        try {
            await makeAuthenticatedRequest(`/roles/${roleId}`, 'DELETE');
            const response = await makeAuthenticatedRequest('/roles', 'GET');
            setRoles(response.data);
            message.success('Rol başarıyla silindi.');
        } catch (error) {
            message.error('Rol silinirken bir hata oluştu: ' + error.toString());
        }
    };

    const handleModalOk = async () => {
        const values = await form.validateFields();
        try {
            if (currentRole) {
                await makeAuthenticatedRequest(`/roles/${currentRole._id}`, 'PUT', values);
            } else {
                await makeAuthenticatedRequest('/roles', 'POST', values);
            }
            const response = await makeAuthenticatedRequest('/roles', 'GET');
            setRoles(response.data);
            setIsModalVisible(false);
            message.success('Rol başarıyla güncellendi.');
        } catch (error) {
            message.error('Rol güncellenirken bir hata oluştu: ' + error.toString());
        }
    };

    const columns = [
        {
            title: 'Rol Adı',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Açıklama',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'İşlemler',
            key: 'action',
            render: (_, record) => (
                <>
                    <Button onClick={() => handleEdit(record)} type="link">Düzenle</Button>
                    <Button onClick={() => handleDelete(record._id)} type="link" danger>Sil</Button>
                </>
            ),
        },
    ];

    return (
        <>
            <Button type="primary" onClick={handleAdd} style={{ marginBottom: 16 }}>
                Rol Ekle
            </Button>
            <Table columns={columns} dataSource={roles} rowKey="_id" />

            <Modal title={currentRole ? 'Rol Düzenle' : 'Yeni Rol'} visible={isModalVisible} onOk={handleModalOk} onCancel={() => setIsModalVisible(false)}>
                <Form form={form} layout="vertical" initialValues={currentRole}>
                    <Form.Item name="name" label="Rol Adı" rules={[{ required: true, message: 'Lütfen rol adı girin!' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="description" label="Açıklama">
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default RoleManagementPage;
