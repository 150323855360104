import React, {useState, useEffect, useCallback} from 'react';
import {Table, Button, Modal, Form, Input, Select, message} from 'antd';
import {useAuth} from '../../context/AuthContext';

const {Option} = Select;

const BalancesPage = () => {
    const [balances, setBalances] = useState([]);
    const [dealers, setDealers] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isPayModalVisible, setIsPayModalVisible] = useState(false); // Ödeme modalı için state

    const [form] = Form.useForm();
    const [payForm] = Form.useForm(); // Ödeme formu için form

    const {makeAuthenticatedRequest} = useAuth();
    const [dealerId, setDealerId] = useState(null); // dealerId state variable added

const fetchFirmDebts = useCallback(async () => {
    try {
        const response = await makeAuthenticatedRequest('/debts/all', 'GET');
        setBalances(response.data);
    } catch (error) {
        message.error('Firmanın borçları yüklenirken bir hata oluştu: ' + error.toString());
    }
}, [makeAuthenticatedRequest]);

useEffect(() => {
    fetchFirmDebts();
}, [fetchFirmDebts]);

    useEffect(() => {
        const fetchDealers = async () => {
            try {
                const response = await makeAuthenticatedRequest('/auth/bayiler', 'GET');
                setDealers(response);
            } catch (error) {
                message.error(`Bayileri çekerken bir hata oluştu: ${error.toString()}`);
            }
        };

        fetchDealers();
    }, [makeAuthenticatedRequest]);

useEffect(() => {
    fetchFirmDebts();
}, [makeAuthenticatedRequest, fetchFirmDebts]);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleAddBalance = async () => {
        try {
            await makeAuthenticatedRequest('/debts/add', 'POST', {dealerId, amount: form.getFieldValue('amount')});
            message.success('Bakiye başarıyla eklendi');
            setIsModalVisible(false);
            form.resetFields();
            fetchFirmDebts(); // Borç listesini güncelle
        } catch (error) {
            message.error('Borç eklenirken bir hata oluştu: ' + error.toString());
        }
    };

    const handlePayDebt = async () => {
        const amount = payForm.getFieldValue('amount');
        try {
            await makeAuthenticatedRequest('/debts/pay', 'POST', {dealerId, amount});
            message.success('Borç başarıyla ödendi');
            fetchFirmDebts(); // Borç listesini güncelle
        } catch (error) {
            message.error('Borç ödenirken bir hata oluştu: ' + error.toString());
        }
    };
    const columns = [
        {
            title: 'Bayi Adı',
            dataIndex: 'dealerName',
            key: 'dealerName',
        },
        {
            title: 'Bakiye TL',
            dataIndex: 'totalDebt', // 'balance' yerine 'totalDebt' kullanıldı
            key: 'totalDebt', // 'balance' yerine 'totalDebt' kullanıldı
        },

    ];

    return (
        <>
            <Button type="primary" onClick={showModal}>
                Borç Ekle
            </Button>
            <span style={{margin: '0 10px'}}></span>
            <Button type="primary" onClick={() => setIsPayModalVisible(true)}>
                Tahsilat Ekle
            </Button>
            <Modal title="Borç Ekle" visible={isModalVisible} onCancel={handleCancel} footer={null}>
                <Form form={form} onFinish={handleAddBalance} layout="vertical">
                    <Form.Item name="dealerId" label="Bayi" rules={[{required: true}]}>
                        <Select placeholder="Bayi seçin" onChange={(value) => setDealerId(value)}>
                            {dealers.map(dealer => (
                                <Option key={dealer._id} value={dealer._id}>{dealer.username}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="amount" label="Miktar" rules={[{required: true}]}>
                        <Input type="number" placeholder="Miktar girin"/>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Ekle
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal title="Tahsilat Ekle" visible={isPayModalVisible} onCancel={() => setIsPayModalVisible(false)}
                   footer={null}>
                <Form form={payForm} onFinish={handlePayDebt} layout="vertical">
                    <Form.Item name="dealerId" label="Firma" rules={[{required: true}]}>
                        <Select placeholder="Firma seçin" onChange={(value) => setDealerId(value)}>
                            {dealers.map(dealer => (
                                <Option key={dealer._id} value={dealer._id}>{dealer.username}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="amount" label="Miktar" rules={[{required: true}]}>
                        <Input type="number" placeholder="Miktar girin"/>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Öde
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Table columns={columns} dataSource={balances} rowKey="user"/>
        </>
    );
};

export default BalancesPage;