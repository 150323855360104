import React from 'react';
import { Form, Input, Button, message } from 'antd';
import { useAuth } from '../../context/AuthContext'; // Adjust the import path as necessary

const BayiEklemeFormu = ({ onBayiAdded }) => {
    const [form] = Form.useForm();
    const { makeAuthenticatedRequest } = useAuth();

    const onFinish = async (values) => {
        try {
            await makeAuthenticatedRequest('/auth/bayiler', 'POST', values);
            form.resetFields();
            onBayiAdded(); // Bayi ekleme işlemi başarılı olduktan sonra bayiler listesini yenile
            message.success('Bayi başarıyla eklendi.');
        } catch (error) {
            console.error('Error adding bayi:', error);
            message.error('Bayi eklenirken bir hata oluştu.');
        }
    };



    return (
        <Form form={form} onFinish={onFinish}>
            <Form.Item
                name="username"
                rules={[{ required: true, message: 'Lütfen bayi adını girin' }]}
            >
                <Input placeholder="Bayi Adı" />
            </Form.Item>
            <Form.Item
                name="email"
                rules={[{ required: true, message: 'Lütfen e-posta adresini girin' }]}
            >
                <Input placeholder="E-posta" />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[{ required: true, message: 'Lütfen bir şifre girin' }]}
            >
                <Input.Password placeholder="Şifre" />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Bayi Ekle
                </Button>
            </Form.Item>
        </Form>
    );
};

export default BayiEklemeFormu;