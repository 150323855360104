
import React from 'react';
import { Layout } from 'antd';
import './Footer.css';

const { Footer } = Layout;

const DashboardFooter = () => {
    return (
        <Footer className="footer">
            Created with love by <a href="https://felixart.com.tr" target="_blank" rel="noopener noreferrer" className="link">felixart</a>
        </Footer>
    );
};

export default DashboardFooter;
