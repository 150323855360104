// ProductList.jsx
import React, {useState, useEffect} from 'react';
import {Table, message, Button} from 'antd';
import {useAuth} from '../../context/AuthContext';

const ProductList = () => {
    const [products, setProducts] = useState([]);
    const {makeAuthenticatedRequest} = useAuth();

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const {data} = await makeAuthenticatedRequest('/products', 'GET');
                console.log(data); // Add this line
                setProducts(data);
            } catch (error) {
                message.error('Ürünler alınırken hata: ' + error.toString());
            }
        };

        fetchProducts();
    }, [makeAuthenticatedRequest]);

    const deleteProduct = async (productId) => {
    try {
        await makeAuthenticatedRequest(`/products/${productId}`, 'DELETE');
        // After successfully deleting the product, remove it from the local state
        setProducts(products.filter(product => product._id !== productId));
        message.success('Product successfully deleted.');
    } catch (error) {
        message.error(`Error deleting product: ${error.message}`);
    }
};

    class ErrorBoundary extends React.Component {
        constructor(props) {
            super(props);
            this.state = {hasError: false};
        }

        static getDerivedStateFromError(error) {
            return {hasError: true};
        }

        componentDidCatch(error, errorInfo) {
            console.error("Error:", error, errorInfo);
        }

        render() {
            if (this.state.hasError) {
                return <h1>Bir hata oluştu. Lütfen daha sonra tekrar deneyin.</h1>;
            }

            return this.props.children;
        }
    }

    const columns = [
        {
            title: 'İsim',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Tür',
            dataIndex: 'productType',
            key: 'productType',
            render: (text, record) => record.productType ? record.productType.name : 'N/A',
        },
        {
            title: 'Kalite',
            dataIndex: 'qualityType',
            key: 'qualityType',
            render: (text, record) => record.qualityType ? record.qualityType.name : 'N/A',
        },


        {
            title: 'Fotoğraf',
            dataIndex: 'photos',
            key: 'photos',
            render: (text, record) => (
                <ErrorBoundary>
                    {record.photos[0] ?
                        <img
                            key={record.photos[0]}
                            width={50}
                            src={`https://sumerlibackend-ens1.onrender.com${record.photos[0].startsWith('/') ? record.photos[0] : '/' + record.photos[0]}`}
                            alt="Ürün"
                        /> :
                        <span>Yükleniyor...</span>
                    }
                </ErrorBoundary>
            ),

        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Button onClick={() => deleteProduct(record._id)}>Sil</Button>
            ),
        },
    ];

    return (
        <Table
            dataSource={products}
            columns={columns}
            rowKey="_id"
            pagination={{pageSize: 5}} // Adjust based on your preference
        />
    );
};

export default ProductList;