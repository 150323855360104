
import React from 'react';
import { Layout } from 'antd';
import DashboardHeader from '../components/Header';
import DashboardFooter from "../components/Footer";
import MyPaymentsRecordPage from "../components/Payments/MyPaymentsRecordPage";

const { Content } = Layout;

const MyPaymentRecords = () => {
    return (
        <Layout className="layout">
            <DashboardHeader activeKey="0"/>
            <br/>
            <Content style={{padding: '0 10px'}}>

                <MyPaymentsRecordPage/>
            </Content>
            <br/>
            <DashboardFooter/>
        </Layout>
    );
};

export default MyPaymentRecords;
