import React, { useState, useEffect } from 'react';
import { Button, Input, Select, message, Divider } from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { useAuth } from '../../context/AuthContext';

const { Option } = Select;

const QualityTypeManager = ({ form, qualityTypes, setQualityTypes, selectedQualityType, setSelectedQualityType }) => {
    const { makeAuthenticatedRequest } = useAuth();
    const [newType, setNewType] = useState('');

    useEffect(() => {
        const fetchQualityTypes = async () => {
            try {
                const response = await makeAuthenticatedRequest('/quality-types', 'GET');
                setQualityTypes(response.data);
            } catch (error) {
                message.error(`Kalite türleri yüklenirken hata oluştu: ${error.toString()}`);
            }
        };

        fetchQualityTypes();
    }, [makeAuthenticatedRequest, setQualityTypes]);

    const handleAddQualityType = async () => {
        if (!newType.trim()) {
            message.error('Kalite türü adı boş olamaz.');
            return;
        }
        try {
            const response = await makeAuthenticatedRequest('/quality-types', 'POST', { name: newType });
            setQualityTypes([...qualityTypes, response.data]);
            setNewType('');
            message.success('Kalite türü başarıyla eklendi.');
        } catch (error) {
            message.error(`Kalite türü eklenirken hata oluştu: ${error.toString()}`);
        }
    };

    const handleDeleteQualityType = async (typeId, event) => {
        event.stopPropagation();
        try {
            await makeAuthenticatedRequest(`/quality-types/${typeId}`, 'DELETE');
            setQualityTypes(qualityTypes.filter(type => type._id !== typeId));
            message.success('Kalite türü başarıyla silindi.');
        } catch (error) {
            message.error(`Kalite türü silinirken hata oluştu: ${error.toString()}`);
        }
    };

    const onTypeChange = (value) => {
        setSelectedQualityType(value);
        form.setFieldsValue({ qualityType: value });
    };

    return (
        <Select
            style={{ width: '100%' }}
            placeholder="Kalite türünü seçin veya ekleyin"
            value={selectedQualityType}
            onChange={onTypeChange}
            dropdownRender={(menu) => (
                <>
                    {menu}
                    <Divider style={{ margin: '8px 0' }} />
                    <div style={{ display: 'flex', alignItems: 'center', padding: '0 8px 4px' }}>
                        <Input value={newType} onChange={(e) => setNewType(e.target.value)} />
                        <Button type="text" icon={<PlusOutlined />} onClick={handleAddQualityType}>
                            Ekle
                        </Button>
                    </div>
                </>
            )}
        >
            {qualityTypes.map((type) => (
                <Option key={type._id} value={type._id}>
                    {type.name}
                    <CloseOutlined onClick={(event) => handleDeleteQualityType(type._id, event)} style={{ float: 'right', display: 'flex', alignItems: 'center' }} />
                </Option>
            ))}
        </Select>
    );
};

export default QualityTypeManager;
