import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const ProtectedRoute = ({ children }) => {
    const { user } = useAuth();

    if (!user) {
        // Kullanıcı giriş yapmamışsa, onları giriş sayfasına yönlendir
        return <Navigate to="/login" replace />;
    }

    // Kullanıcı giriş yapmışsa, çocuk bileşenleri (protected component) render et
    return children;
};

export default ProtectedRoute;
