import React, { useState } from 'react';
import { Form, DatePicker, Button, Select, Table, message, Layout, Typography, Card } from 'antd';
import { useAuth } from '../../context/AuthContext';
import moment from 'moment';
import 'moment/locale/tr';
import './Transaction.css';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Content } = Layout;
const { Title } = Typography;

const Transactions = () => {
    const [transactions, setTransactions] = useState([]);
    const { makeAuthenticatedRequest } = useAuth();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const fetchTransactions = async (values) => {
        const [startDate, endDate] = values.dateRange;
        const formattedValues = {
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD'),
            transactionType: values.transactionType || '',
            transactionStatus: values.transactionStatus || '',
        };

        try {
            setLoading(true);
            const response = await makeAuthenticatedRequest('/paramlist/transactions', 'POST', formattedValues);

            if (response) {
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(response, "text/xml");

                const data = Array.from(xmlDoc.getElementsByTagName('Temp')).map((item) => ({
                    SanalPOS_Islem_ID: item.getElementsByTagName('SanalPOS_Islem_ID')[0].textContent,
                    Sonuc_Str: item.getElementsByTagName('Sonuc_Str')[0].textContent,
                    Tip_Str: item.getElementsByTagName('Tip_Str')[0].textContent,
                    Tarih: moment(item.getElementsByTagName('Tarih')[0].textContent).format('DD.MM.YYYY HH:mm:ss'),
                    Odeme_Yapan_Bilgisi: item.getElementsByTagName('Odeme_Yapan_Bilgisi')[0].textContent,
                    Komisyon_Oran: item.getElementsByTagName('Komisyon_Oran')[0].textContent,
                    Komisyon_Tutar: item.getElementsByTagName('Komisyon_Tutar')[0].textContent,
                    Net_Tutar: item.getElementsByTagName('Net_Tutar')[0].textContent,
                    Tutar: item.getElementsByTagName('Tutar')[0].textContent,
                }));

                setTransactions(data);
            } else {
                message.error('Veriler alınırken bir hata oluştu.');
            }
        } catch (error) {
            console.error('İşlemler yüklenirken hata oluştu:', error.message);
            message.error(`Veriler alınırken bir hata oluştu: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const renderTransactionTypeTag = (type) => {
        let color;
        switch (type) {
            case 'Satış':
                color = '#f3c506';
                break;
            case 'İade':
                color = '#ec6825';
                break;
            case 'İptal':
                color = 'rgb(245,115,170)';
                break;
            default:
                color = '#e6f7ff';
                break;
        }
        return <div style={{ backgroundColor: color, padding: '8px', textAlign: 'center', borderRadius: '4px' }}>{type}</div>;
    };

    const renderTransactionStatusTag = (status) => {
        let color;
        switch (status) {
            case 'Başarılı':
                color = '#f1343f';
                break;
            case 'Başarısız':
                color = '#8a8a8a';
                break;
            default:
                color = '#e6f7ff';
                break;
        }
        return <div style={{ backgroundColor: color, padding: '8px', textAlign: 'center', borderRadius: '4px' }}>{status}</div>;
    };

    const columns = [
        {
            title: 'Sanal POS İşlem ID',
            dataIndex: 'SanalPOS_Islem_ID',
            key: 'SanalPOS_Islem_ID',
            width: 150,
            align: 'center',
            render: text => <strong>{text}</strong>,
        },
        {
            title: 'Sonuç Açıklama',
            dataIndex: 'Sonuc_Str',
            key: 'Sonuc_Str',
            render: renderTransactionStatusTag,
            width: 100,
            align: 'center',
        },
        {
            title: 'İşlem Tipi',
            dataIndex: 'Tip_Str',
            key: 'Tip_Str',
            render: renderTransactionTypeTag,
            width: 100,
            align: 'center',
        },
        {
            title: 'Tarih',
            dataIndex: 'Tarih',
            key: 'Tarih',
            width: 180,
            align: 'center',
            render: date => <span>{date}</span>,
        },
        {
            title: 'Ödeme Yapan Bilgisi',
            dataIndex: 'Odeme_Yapan_Bilgisi',
            key: 'Odeme_Yapan_Bilgisi',
            width: 250,
            align: 'center',
            render: text => <span style={{ whiteSpace: 'pre-wrap' }}>{text}</span>,
        },
        {
            title: 'Komisyon Oranı',
            dataIndex: 'Komisyon_Oran',
            key: 'Komisyon_Oran',
            width: 100,
            align: 'right',
            render: text => <span>{parseFloat(text).toFixed(2)}%</span>,
        },
        {
            title: 'Komisyon Tutarı',
            dataIndex: 'Komisyon_Tutar',
            key: 'Komisyon_Tutar',
            width: 150,
            align: 'right',
            render: text => <span>{parseFloat(text).toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}</span>,
        },
        {
            title: 'Net Tutar',
            dataIndex: 'Net_Tutar',
            key: 'Net_Tutar',
            width: 150,
            align: 'right',
            render: text => <span>{parseFloat(text).toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}</span>,
        },
        {
            title: 'Toplam Tutar',
            dataIndex: 'Tutar',
            key: 'Tutar',
            width: 150,
            align: 'right',
            render: text => <span>{parseFloat(text).toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}</span>,
        },
    ];

    return (
        <Layout style={{ padding: '24px', backgroundColor: '#f0f2f5' }}>
            <Content>
                <Card style={{ borderRadius: '8px' }}>
                    <Title level={2} style={{ textAlign: 'center', marginBottom: '24px' }}>İşlem Listesi</Title>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={fetchTransactions}
                        className="transaction-form"
                    >
                        <Form.Item
                            name="dateRange"
                            label="Tarih Aralığı"
                            rules={[{ required: true, message: 'Lütfen tarih aralığını girin!' }]}
                        >
                            <RangePicker format="YYYY-MM-DD" style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item
                            name="transactionType"
                            label="İşlem Tipi"
                        >
                            <Select placeholder="İşlem Tipini Seçin" style={{ width: '100%' }}>
                                <Option value="">Hepsi</Option>
                                <Option value="Satis">Satış</Option>
                                <Option value="Iade">İade</Option>
                                <Option value="Iptal">İptal</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="transactionStatus"
                            label="İşlem Durumu"
                        >
                            <Select placeholder="İşlem Durumunu Seçin" style={{ width: '100%' }}>
                                <Option value="">Hepsi</Option>
                                <Option value="Basarili">Başarılı</Option>
                                <Option value="Basarisiz">Başarısız</Option>
                            </Select>
                        </Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading} block>
                            {loading ? 'Yükleniyor...' : 'İşlemleri Getir'}
                        </Button>
                    </Form>

                    <Table
                        className="transactions-table"
                        columns={columns}
                        dataSource={transactions}
                        rowKey="SanalPOS_Islem_ID"
                        loading={loading}
                        pagination={{ pageSize: 10 }}
                        style={{ marginTop: '24px' }}
                        bordered
                    />
                </Card>
            </Content>
        </Layout>
    );
};

export default Transactions;
