import { Table, Button, Popconfirm, message } from 'antd';
import { useAuth } from '../../context/AuthContext'; // Adjust the import path as necessary

const BayiListesi = ({ bayiler, setBayiler }) => {
    const { makeAuthenticatedRequest } = useAuth();


    const handleDelete = async (userId) => {
        try {
            await makeAuthenticatedRequest(`/auth/bayiler/${userId}`, 'DELETE');
            const updatedBayiler = bayiler.filter(bayi => bayi._id !== userId);
            setBayiler(updatedBayiler);
            message.success('Bayi başarıyla silindi.');
        } catch (error) {
            message.error('Bayi silinirken bir hata oluştu: ' + error.toString());
        }
    };

    const columns = [
        {
            title: 'Bayi Adı',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'E-posta',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'İşlemler',
            key: 'actions',
            render: (text, record) => (
                <Popconfirm
                    title="Bu bayiyi silmek istediğinize emin misiniz?"
                    onConfirm={() => handleDelete(record._id)}
                >
                    <Button type="danger">Sil</Button>
                </Popconfirm>
            ),
        },
    ];

    return <Table dataSource={bayiler} columns={columns} rowKey="_id" />;
};

export default BayiListesi;
