import React, { useEffect, useState, useCallback } from 'react';
import BayiListesi from './BayiListesi';
import BayiEklemeFormu from './BayiEklemeForm';
import { Card } from 'antd';
import { useAuth } from '../../context/AuthContext';

const BayiPage = () => {
    const [bayiler, setBayiler] = useState([]);
    const { makeAuthenticatedRequest } = useAuth();

    // Wrap fetchBayiler function with useCallback
    const fetchBayiler = useCallback(async () => {
        try {
            const response = await makeAuthenticatedRequest('/auth/bayiler', 'GET');
            setBayiler(response); // Ensure the correct property from response is used to update state
        } catch (error) {
            console.error('Bayileri yüklerken hata oluştu:', error);
        }
    }, [makeAuthenticatedRequest]); // Add makeAuthenticatedRequest to the dependency array

    // Use fetchBayiler inside useEffect
    useEffect(() => {
        fetchBayiler();
    }, [fetchBayiler]); // Now fetchBayiler is a dependency

    return (
        <div>
            <Card title="Bayi Ekle">
                <BayiEklemeFormu onBayiAdded={fetchBayiler} />
            </Card>
            <Card title="Bayiler Listesi">
                <BayiListesi bayiler={bayiler} setBayiler={setBayiler} />
            </Card>
        </div>
    );
};

export default BayiPage;
