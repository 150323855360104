import React, { useState, useEffect } from 'react';
import { message, Button } from 'antd';
import { useAuth } from '../../context/AuthContext';
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import '../Table.css';
const PaymentsPage = () => {
    const [payments, setPayments] = useState([]);
    const { makeAuthenticatedRequest } = useAuth();
    const [bayiNames, setBayiNames] = useState({});

    useEffect(() => {
        const fetchReceipts = async () => {
            try {
                const response = await makeAuthenticatedRequest('/param/receipts', 'GET');
                const bayiResponse = await makeAuthenticatedRequest('/auth/bayiler', 'GET');
                const bayiNames = bayiResponse.reduce((acc, bayi) => ({ ...acc, [bayi._id]: bayi.username }), {});
                setBayiNames(bayiNames);

                if (!response || !Array.isArray(response)) {
                    message.info('Hiç dekont bulunamadı.');
                } else {
                    setPayments(response.map(item => ({
                        ...item,
                        bayiId: bayiNames[item.bayiId] || item.bayiId,
                    })));
                }
            } catch (error) {
                console.error('Dekontlar yüklenirken bir hata oluştu:', error);
                message.error('Dekontlar yüklenirken bir hata oluştu.');
            }
        };
        fetchReceipts();
    }, [makeAuthenticatedRequest, bayiNames]);

    return (
        <div className="table-wrapper">
            <table className="responsive-table">
                <thead>
                <tr>
                    <th>Dekont Numarası</th>
                    <th>Ödeme ID</th>
                    <th>Bayi Adı</th>
                    <th>Miktar</th>
                    <th>Tahsilat Tutarı</th>
                    <th>Durum</th>
                    <th>Dekont İndir</th>
                </tr>
                </thead>
                <tbody>
                {payments.map(payment => (
                    <tr key={payment._id}>
                        <td data-label="Dekont Numarası">{payment.dekontID}</td>
                        <td data-label="Ödeme ID">{payment.siparisID}</td>
                        <td data-label="Bayi Adı">{payment.bayiId}</td>
                        <td data-label="Miktar">{payment.odemeTutari} TL</td>
                        <td data-label="Tahsilat Tutarı">{payment.tahsilatTutari} TL</td>
                        <td data-label="Durum">
                            {payment.sonuc === 'Başarılı' ?
                                <CheckCircleOutlined style={{ color: 'green' }} /> :
                                <CloseCircleOutlined style={{ color: 'red' }} />}
                        </td>
                        <td data-label="Dekont İndir">
                            <Button type="primary" onClick={() => window.open(`https://sumerlibackend-ens1.onrender.com/api/param/download-receipt?dekont_id=${payment.dekontID}`)}>
                                İndir
                            </Button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default PaymentsPage;
