import React from 'react';
import {Card, Col, Row} from 'antd';
import DealerProducts from "./MyProductsList";

const ProductsPage = () => {
    return (
        <div className="products-page-container">
            <Row gutter={16}>
                <Col span={24}>
                    <Card title="Ürün Listesi" bordered={false}>
                        <DealerProducts />
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default ProductsPage;