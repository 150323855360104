import React from 'react';
import {Layout, Row, Col} from 'antd';
import DashboardHeader from '../components/Header';
import DashboardFooter from "../components/Footer";
import DashboardPageComponent from "../components/Dashboard/DashboardPage";

const {Content} = Layout;

const DashboardPage = () => {
    return (
        <Layout className="layout">
            <DashboardHeader activeKey="0"/>
            <br/>
            <Content style={{padding: '0 10px'}}>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <DashboardPageComponent/>
                    </Col>
                </Row>
            </Content>
            <br/>
            <DashboardFooter/>
        </Layout>
    );
};

export default DashboardPage;