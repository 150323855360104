import React, {useState, useEffect} from 'react';
import {Form, Input, Button, Select, Upload, message} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import {useAuth} from '../../context/AuthContext';
import ProductTypeManager from "./ProductTypeManager";
import QualityTypeManager from "./QualityTypeManager";
import LengthsManager from "./LengthsManager";
import AnnealingOptionsManager from "./AnnealingOptionsManager";
import WireDiameterOptionsManager from "./WireDiameterOptionsManager";

const {Option} = Select;

const AddProductForm = ({onProductAdded}) => {
    const [form] = Form.useForm();
    const {makeAuthenticatedRequest} = useAuth();
    const [dealers, setDealers] = useState([]);
    const [productTypes, setProductTypes] = useState([]);
    const [qualityTypes, setQualityTypes] = useState([]);
    const [lengths, setLengths] = useState([]);
    const [annealings, setAnnealings] = useState([]);
    const [wireDiameters, setWireDiameters] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [selectedProductType, setSelectedProductType] = useState(undefined);
    const [selectedQualityType, setSelectedQualityType] = useState(undefined);
    const [selectedLengths, setSelectedLengths] = useState(undefined);
    const [selectedAnnealing, setSelectedAnnealing] = useState(undefined); // Yeni state ekleyin
    const [selectedWireDiameter, setSelectedWireDiameter] = useState(undefined);


    useEffect(() => {
        const fetchDealers = async () => {
            try {
                const response = await makeAuthenticatedRequest('/auth/bayiler', 'GET');
                setDealers(response);
            } catch (error) {
                message.error(`Bayileri çekerken bir hata oluştu: ${error.toString()}`);
            }
        };

        fetchDealers();
    }, [makeAuthenticatedRequest]);

    const handleSubmit = async (values) => {
        console.log('Gönderilen veriler:', values);

        const formData = new FormData();

        Object.keys(values).forEach(key => {
            if (key !== 'photos' && values[key] !== null && values[key] !== undefined) { // `null` ve `undefined` değerleri atla
                formData.append(key, values[key]);
                console.log(`FormData'ya eklenen: ${key}: ${values[key]}`); // Her bir değeri logla
            }
        });

        // Dosyaları formData'ya ekleyin
        if (fileList && fileList.length > 0) {
            fileList.forEach(file => {
                formData.append('photos', file.originFileObj);
                console.log(`FormData'ya eklenen dosya: ${file.name}`); // Her bir dosyayı logla
            });
        }

        try {
            await makeAuthenticatedRequest('/products/add', 'POST', formData, {
                'Content-Type': 'multipart/form-data',
            });
            message.success('Ürün başarıyla eklendi.');
            onProductAdded(); // Ürün eklendiğinde çağır
        } catch (error) {
            message.error(`Ürün eklenirken bir hata oluştu: ${error.message}`);
        }
    };

    const uploadButton = (
        <div>
            <PlusOutlined/>
            <div style={{marginTop: 8}}>Upload</div>
        </div>
    );
    const onFormValuesChange = (_, allValues) => {

        if (allValues.productType) {
            setSelectedProductType(allValues.productType);
        }
    };


    return (
        <Form form={form} onFinish={handleSubmit} layout="vertical" onValuesChange={onFormValuesChange}>
            <Form.Item name="name" label="Ürün Adı" rules={[{required: true}]}>
                <Input placeholder="Ürün adını girin"/>
            </Form.Item>

            <Form.Item name="productType" label="Ürün Türü" rules={[{required: false}]}>
                <ProductTypeManager
                    form={form}
                    productTypes={productTypes}
                    setProductTypes={setProductTypes}
                    selectedProductType={selectedProductType}
                    setSelectedProductType={setSelectedProductType}
                />
            </Form.Item>

            <Form.Item name="qualityType" label="Kalite Türü" rules={[{required: false}]}>
                <QualityTypeManager
                    form={form}
                    qualityTypes={qualityTypes}
                    setQualityTypes={setQualityTypes}
                    selectedQualityType={selectedQualityType}
                    setSelectedQualityType={setSelectedQualityType}
                />
            </Form.Item>


            <Form.Item name="length" label="Uzunluk" rules={[{required: false}]}>
                <LengthsManager
                    form={form}
                    lengths={lengths}
                    setLengths={setLengths}
                    selectedLengths={selectedLengths}
                    setSelectedLengths={setSelectedLengths}
                />
            </Form.Item>

            <Form.Item name="annealing" label="Tavlama" rules={[{required: false}]}>
                <AnnealingOptionsManager
                    form={form}
                    annealings={annealings}
                    setAnnealings={setAnnealings}
                    selectedAnnealing={selectedAnnealing}
                    setSelectedAnnealing={setSelectedAnnealing}
                />
            </Form.Item>

            <Form.Item name="wireDiameter" label="Tel Çapı" rules={[{required: true}]}>
                <WireDiameterOptionsManager
                    form={form}
                    wireDiameters={wireDiameters}
                    setWireDiameters={setWireDiameters}
                    selectedWireDiameter={selectedWireDiameter}
                    setSelectedWireDiameter={setSelectedWireDiameter}
                />
            </Form.Item>

            <Form.Item name="description" label="Açıklama" rules={[{required: false}]}>
                <Input.TextArea placeholder="Ürün açıklamasını girin"/>
            </Form.Item>

            <Form.Item name="assignedDealers" label="Atanan Bayiler">
                <Select mode="multiple" placeholder="Bayi seçin" allowClear>
                    {dealers.map(dealer => (
                        <Option key={dealer._id} value={dealer._id}>{dealer.username}</Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item
                name="photos"
                label="Ürün Fotoğrafları"
                valuePropName="fileList"
                getValueFromEvent={(e) => {
                    if (Array.isArray(e)) {
                        return e;
                    }
                    return e && e.fileList;
                }}
                rules={[{required: false, message: 'Lütfen ürün fotoğrafı yükleyin.'}]}
            >
                <Upload
                    name="photos"
                    listType="picture-card"
                    multiple={true}
                    beforeUpload={() => false}
                    onChange={(e) => setFileList(e.fileList)}
                >
                    {fileList.length >= 10 ? null : uploadButton}
                </Upload>
            </Form.Item>


            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Ürün Ekle
                </Button>
            </Form.Item>
        </Form>
    );
};

export default AddProductForm;
