import React, {useEffect, useState, useCallback} from 'react';
import {Card, Col, Row, message} from 'antd';
import AddProductForm from './AddProductForm';
import ProductList from './ProductList'; // Assume we have a ProductList component
import {useAuth} from '../../context/AuthContext'; // Adjust this import path as necessary

const ProductsPage = () => {
    const [products, setProducts] = useState([]);
    const {makeAuthenticatedRequest} = useAuth();

    const fetchProducts = useCallback(async () => {
        try {
            const response = await makeAuthenticatedRequest('/products', 'GET');
            setProducts(response.data || []); // Assuming response structure has a data property
        } catch (error) {
            message.error('Failed to load products: ' + error.toString());
        }
    }, [makeAuthenticatedRequest]); // makeAuthenticatedRequest is a dependency

    const handleProductAdded = () => {
        fetchProducts(); // Refresh the product list after adding a new product
    };

    useEffect(() => {
        fetchProducts();
    }, [fetchProducts]); // Now fetchProducts is memoized and safe to include as a dependency


    return (
        <div className="products-page-container">
            <Row gutter={16}>
                <Col span={24}>
                    <Card title="Yeni Ürün Ekle" bordered={false}>
                        <AddProductForm onProductAdded={handleProductAdded}/>
                    </Card>
                </Col>
                <br/>
                <Col span={24}>
                    <Card title="Product List" bordered={false}>
                        <ProductList products={products} setProducts={setProducts}/>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default ProductsPage;
