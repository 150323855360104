import React from 'react';
import {Layout} from 'antd';
import DashboardHeader from '../components/Header';
import DashboardFooter from "../components/Footer";
import MyProductsPage from "../components/product/MyProductsPage";


const {Content} = Layout;
const Products = () => {
    return (
        <Layout className="layout">
            <DashboardHeader activeKey="0"/>
            <br/>
            <Content style={{padding: '0 10px'}}>
                <MyProductsPage/>
            </Content>
            <br/>
            <DashboardFooter/>
        </Layout>
    );
};

export default Products;
