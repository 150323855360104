import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import Dashboard from "./pages/Dashboard";
import UsersPage from "./pages/Users";
import ProtectedRoute from './components/ProtectedRoute';
import LoginPage from './pages/auth/LoginPage';
import Products from "./pages/Products";
import Orders from "./pages/Orders";
import Payments from "./pages/Payments";
import Balances from "./pages/Balances";
import UsersRole from "./pages/UsersRole";
import MyPayments from "./pages/MyPayments";
import Success from "./pages/Success";
import FirmaDashboard from "./pages/FirmaDashboard";
import MyProducts from "./pages/MyProducts";
import MyOrders from "./pages/MyOrders";
import MyPaymentRecords from "./pages/MyPaymentsRecord";
import PaymentDone from "./pages/PaymentDone";
import PaymentFail from "./pages/PaymentFail";
import TransactionList from "./pages/TransactionList";
import PaymentWithCommision from "./pages/PaymentWithCommision";
import PaymentCallback from "./pages/PaymentCallback";

function App() {
    useAuth();

    return (
        <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route
                path="/"
                element={
                    <ProtectedRoute>
                        <Dashboard />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/bayiler"
                element={
                    <ProtectedRoute>
                        <UsersPage />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/urunler" // Adding the products page route
                element={
                    <ProtectedRoute>
                        <Products />
                    </ProtectedRoute>
                }
            />

            <Route
                path="/siparisler" // Adding the products page route
                element={
                    <ProtectedRoute>
                        <Orders />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/tahsilatlar" // Adding the products page route
                element={
                    <ProtectedRoute>
                        <Payments />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/odemelerim" // Adding the products page route
                element={
                    <ProtectedRoute>
                        <MyPaymentRecords />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/bakiye" // Adding the products page route
                element={
                    <ProtectedRoute>
                        <Balances />
                    </ProtectedRoute>
                }
            />

            <Route
                path="/kullanicilar" // Adding the products page route
                element={
                    <ProtectedRoute>
                        <UsersRole />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/dashboard" // Adding the products page route
                element={
                    <ProtectedRoute>
                        <Dashboard />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/odeme-yap" // Adding the products page route
                element={
                    <ProtectedRoute>
                        <MyPayments />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/success"
                element={
                    <ProtectedRoute>
                        <Success />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/firmadashboard"
                element={
                    <ProtectedRoute>
                        <FirmaDashboard />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/urunlerim"
                element={
                    <ProtectedRoute>
                        <MyProducts />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/siparislerim"
                element={
                    <ProtectedRoute>
                        <MyOrders />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/payment-success"
                element={

                    <PaymentDone />

                }
            />
            <Route
                path="/payment-failure"
                element={

                    <PaymentFail />

                }
            />
            <Route
                path="/paramlist"
                element={

                    <TransactionList />

                }
            />
            <Route
                path="/withcommission"
                element={

                    <PaymentWithCommision />

                }
            />
            <Route
                path="/payment-callback"
                element={

                    <PaymentCallback />

                }
            />

        </Routes>
    );
}

export default App;
