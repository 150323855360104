import React from 'react';
import { Layout, Row, Col } from 'antd';
import DashboardHeader from '../components/Header';
import DashboardFooter from "../components/Footer";
import MakePayment from "../components/Payments/MakePayment";
import MyPaymentsNote from "../components/Payments/MyPaymentsNote";

const { Content } = Layout;

const MyPayments = () => {
    return (
        <Layout className="layout">
            <DashboardHeader activeKey="0"/>
            <br/>
            <Content style={{padding: '0 10px'}}>
                <Row gutter={[16, 16]}>

                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div style={{ overflow: 'hidden' }}>
                            <MyPaymentsNote/>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <MakePayment/>
                    </Col>
                </Row>
            </Content>
            <br/>
            <DashboardFooter/>
        </Layout>
    );
};

export default MyPayments;