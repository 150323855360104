import React, {useState, useEffect} from 'react';
import {Button, Input, Select, message, Divider} from 'antd';
import {PlusOutlined, CloseOutlined} from '@ant-design/icons';
import {useAuth} from '../../context/AuthContext';

const {Option} = Select;

const LengthsManager = ({form, lengths, setLengths, selectedLengths, setSelectedLengths}) => {
    const {makeAuthenticatedRequest} = useAuth();
    const [newLength, setNewLength] = useState('');

    useEffect(() => {
        const fetchLengths = async () => {
            try {
                const response = await makeAuthenticatedRequest('/length-options', 'GET');
                setLengths(response.data);
            } catch (error) {
                message.error(`Uzunluk seçenekleri yüklenirken bir hata oluştu: ${error.toString()}`);
            }
        };

        fetchLengths();
    }, [makeAuthenticatedRequest, setLengths]);

    const handleAddLength = async () => {
        if (!newLength.trim()) {
            message.error('Uzunluk adı boş olamaz.');
            return;
        }
        try {
            const response = await makeAuthenticatedRequest('/length-options', 'POST', {value: newLength});
            setLengths([...lengths, response.data]);
            setNewLength('');
            message.success('Uzunluk başarıyla eklendi.');
        } catch (error) {
            message.error(`Uzunluk eklenirken bir hata oluştu: ${error.toString()}`);
        }
    };

    const handleDeleteLength = async (lengthId) => {
        try {
            await makeAuthenticatedRequest(`/length-options/${lengthId}`, 'DELETE');
            setLengths(lengths.filter(length => length._id !== lengthId));
            message.success('Uzunluk başarıyla silindi.');
        } catch (error) {
            message.error(`Uzunluk silinirken bir hata oluştu: ${error.toString()}`);
        }
    };

const onLengthChange = (value) => {
    setSelectedLengths(value);
    form.setFieldsValue({ length: value });
};
    return (
        <Select
            style={{width: '100%'}}
            placeholder="Uzunluk seçin veya ekleyin"
            value={selectedLengths}
            onChange={onLengthChange}
            dropdownRender={(menu) => (
                <>
                    {menu}
                    <Divider style={{margin: '8px 0'}}/>
                    <div style={{display: 'flex', alignItems: 'center', padding: '0 8px 4px'}}>
                        <Input value={newLength} onChange={(e) => setNewLength(e.target.value)}/>
                        <Button type="text" icon={<PlusOutlined/>} onClick={handleAddLength}>
                            Ekle
                        </Button>
                    </div>
                </>
            )}
        >
            {lengths.map((length) => (
                <Option key={length._id} value={length._id}>
                    {length.value}
                    <CloseOutlined onClick={() => handleDeleteLength(length._id)}
                                   style={{float: 'right', display: 'flex', alignItems: 'center'}}/>
                </Option>
            ))}
        </Select>
    );
};

export default LengthsManager;