import React, { useState, useEffect } from 'react';
import { Table, Tag, Space } from 'antd';
import { useAuth } from '../../context/AuthContext';

const OrdersPage = () => {
    const [orders, setOrders] = useState([]);
    const { makeAuthenticatedRequest } = useAuth();

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await makeAuthenticatedRequest('/orders', 'GET');
                setOrders(response.data);
            } catch (error) {
                console.error('Siparişler yüklenirken hata oluştu:', error);
            }
        };

        fetchOrders();
    }, [makeAuthenticatedRequest]);

    const columns = [
        {
            title: 'Sipariş ID',
            dataIndex: '_id',
            key: '_id',
        },
        {
            title: 'Ürünler',
            dataIndex: 'products',
            key: 'products',
            render: products => (
                <>
                    {products.map(product => (
                        <Tag color="blue" key={product.product._id}>
                            {product.product.name} (x{product.quantity})
                        </Tag>
                    ))}
                </>
            ),
        },
        {
            title: 'Toplam Tutar',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
        },
        {
            title: 'Durum',
            key: 'status',
            dataIndex: 'status',
            render: status => (
                <Tag color={status === 'onaylandı' ? 'green' : 'volcano'}>
                    {status.toUpperCase()}
                </Tag>
            ),
        },
        {
            title: 'İşlemler',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    {/* Burada sipariş üzerinde işlem yapmak için butonlar eklenebilir. */}
                </Space>
            ),
        },
    ];

    return <Table columns={columns} dataSource={orders} rowKey="_id" />;
};

export default OrdersPage;
