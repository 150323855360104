import React, {useState, useEffect} from 'react';
import {Button, Input, Select, message, Divider} from 'antd';
import {PlusOutlined, CloseOutlined} from '@ant-design/icons';
import {useAuth} from '../../context/AuthContext';

const {Option} = Select;

const AnnealingOptionsManager = ({form, annealings, setAnnealings, selectedAnnealing, setSelectedAnnealing}) => {
    const {makeAuthenticatedRequest} = useAuth();
    const [newAnnealing, setNewAnnealing] = useState('');

    useEffect(() => {
        const fetchAnnealings = async () => {
            try {
                const response = await makeAuthenticatedRequest('/annealing-options', 'GET');
                setAnnealings(response.data);
            } catch (error) {
                message.error(`Tavlama seçenekleri yüklenirken bir hata oluştu: ${error.toString()}`);
            }
        };

        fetchAnnealings();
    }, [makeAuthenticatedRequest, setAnnealings]);

    const handleAddAnnealing = async () => {
        if (!newAnnealing.trim()) {
            message.error('Tavlama adı boş olamaz.');
            return;
        }
        try {
            const response = await makeAuthenticatedRequest('/annealing-options', 'POST', {value: newAnnealing});
            setAnnealings([...annealings, response.data]);
            setNewAnnealing('');
            message.success('Tavlama başarıyla eklendi.');
        } catch (error) {
            message.error(`Tavlama eklenirken bir hata oluştu: ${error.toString()}`);
        }
    };

    const handleDeleteAnnealing = async (annealingId) => {
        try {
            await makeAuthenticatedRequest(`/annealing-options/${annealingId}`, 'DELETE');
            setAnnealings(annealings.filter(annealing => annealing._id !== annealingId));
            message.success('Tavlama başarıyla silindi.');
        } catch (error) {
            message.error(`Tavlama silinirken bir hata oluştu: ${error.toString()}`);
        }
    };

    const onAnnealingChange = (value) => {
        setSelectedAnnealing(value);
        form.setFieldsValue({annealing: value});
    };

    return (
        <Select
            style={{width: '100%'}}
            placeholder="Tavlama seçin veya ekleyin"
            value={selectedAnnealing}
            onChange={onAnnealingChange}
            dropdownRender={(menu) => (
                <>
                    {menu}
                    <Divider style={{margin: '8px 0'}}/>
                    <div style={{display: 'flex', alignItems: 'center', padding: '0 8px 4px'}}>
                        <Input value={newAnnealing} onChange={(e) => setNewAnnealing(e.target.value)}/>
                        <Button type="text" icon={<PlusOutlined/>} onClick={handleAddAnnealing}>
                            Ekle
                        </Button>
                    </div>
                </>
            )}
        >
            {annealings.map((annealing) => (
                <Option key={annealing._id} value={annealing._id}>
                    {annealing.value}
                    <CloseOutlined onClick={() => handleDeleteAnnealing(annealing._id)}
                                   style={{float: 'right', display: 'flex', alignItems: 'center'}}/>
                </Option>
            ))}
        </Select>
    );
};

export default AnnealingOptionsManager;
