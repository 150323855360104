import React from 'react';
import {useLocation} from 'react-router-dom';

const PaymentDone = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const paymentData = {};
    for (let [key, value] of queryParams.entries()) {
        paymentData[key] = value;
    }

    return (
        <div>
            <h1>Ödeme Başarılı!</h1>
            <p><strong>Sipariş ID:</strong> {paymentData.TURKPOS_RETVAL_Siparis_ID}</p>
            <p><strong>Dekont ID:</strong> {paymentData.TURKPOS_RETVAL_Dekont_ID}</p>
            <p>Ödemeniz başarıyla tamamlanmıştır.</p>
            <a href={`https://sumerlibackend-ens1.onrender.com/api/param/download-receipt?dekont_id=${paymentData.TURKPOS_RETVAL_Dekont_ID}`}
               target="_blank" rel="noopener noreferrer">Dekontu İndir</a>
        </div>
    );
};

export default PaymentDone;