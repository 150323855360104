import React, { useState, useEffect } from 'react';
import { Card, Typography, message } from 'antd';
import { useAuth } from '../../context/AuthContext';

const { Title, Paragraph } = Typography;

const MyPaymentsNote = () => {
    const [totalDebt, setTotalDebt] = useState(0);
    const [notes, setNotes] = useState('');
    const { makeAuthenticatedRequest } = useAuth();

    useEffect(() => {
        const fetchMyDebts = async () => {
            try {
                const response = await makeAuthenticatedRequest('/debts/myDebts', 'GET');
                setTotalDebt(response.totalDebt);
                setNotes(response.notes || ''); // Notları da response'dan alıyoruz
            } catch (error) {
                message.error('Borç bilgileri yüklenirken bir hata oluştu: ' + error.toString());
            }
        };
        fetchMyDebts();
    }, [makeAuthenticatedRequest]);

    return (
        <Card title="Firma Özeti">
            <Title level={2} style={{ color: 'red' }}>Güncel Borç: {totalDebt} TL</Title>
            <Title level={5}>Muhasebe departmanı tarafından belirtilen notlar:</Title>
            <ul>
                <li>
                    <Typography.Text>
                        Türk Lirası olarak ödeme yaparsanız, türk lirası borcunuzdan düşülecektir. Sistemde bu düşüşü
                        görebilirsiniz.
                    </Typography.Text>
                </li>
                <li>
                    <Typography.Text>
                        Dolar olarak ödeme yaparsanız, dolar borcunuzun güncellenmesini bekleyiniz.
                    </Typography.Text>
                </li>
                <li>
                    <Typography.Text>
                        Dolar olarak ödeme yapmadan önce, güncel kur bilgisi için bize ulaşmanızı rica ederiz.
                    </Typography.Text>
                </li>
            </ul>
            <Paragraph>{notes}</Paragraph>
        </Card>
    );
};

export default MyPaymentsNote;