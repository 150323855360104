import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Statistic, Table, message } from 'antd';
import { WalletOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useAuth } from '../../context/AuthContext';

const DashboardPage = () => {
    const [totalDebt, setTotalDebt] = useState(0);
    const [myDebts, setMyDebts] = useState([]);
    const { makeAuthenticatedRequest } = useAuth();

    useEffect(() => {
        const fetchMyDebts = async () => {
            try {
                const response = await makeAuthenticatedRequest('/debts/myDebts', 'GET');
                setTotalDebt(response.totalDebt);
                setMyDebts(response.data);
            } catch (error) {
                message.error('Toplam borç yüklenirken bir hata oluştu: ' + error.toString());
            }
        };

        fetchMyDebts();
    }, [makeAuthenticatedRequest]);

    const collectionsColumns = [
        {
            title: 'Tarih',
            dataIndex: 'date',
            key: 'date',
            render: text => moment(text).format('YYYY-MM-DD'),
        },
        {
            title: 'Miktar',
            dataIndex: 'amount',
            key: 'amount',
        },
    ];

    return (
        <div>
            <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Card>
                        <Statistic
                            title="Güncel Borç"
                            precision={2}
                            value={totalDebt}
                            valueStyle={{ color: '#cf1322' }}
                            prefix={<WalletOutlined />}
                        />
                    </Card>
                </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: '20px' }}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Card title="Borçlarım">
                        <Table columns={collectionsColumns} dataSource={myDebts} rowKey="_id" pagination={false} />
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default DashboardPage;