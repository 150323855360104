
import React from 'react';
import { Layout } from 'antd';
import DashboardHeader from '../components/Header';
import DashboardFooter from "../components/Footer";
import PaymentsPage from "../components/Payments/PaymentsPage";

const { Content } = Layout;

const Payments = () => {
    return (
        <Layout className="layout">
            <DashboardHeader activeKey="0"/>
            <br/>
            <Content style={{padding: '0 10px'}}>

                <PaymentsPage/>
            </Content>
            <br/>
            <DashboardFooter/>
        </Layout>
    );
};

export default Payments;
