import React, {useEffect, useState} from 'react';
import { Layout, Menu, Button, Drawer } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import {
    HomeOutlined,
    AppstoreOutlined,
    ShoppingCartOutlined,
    UserOutlined,
    MenuOutlined,
    LogoutOutlined,
    WalletOutlined
} from '@ant-design/icons';
import './Header.css';

const { Header, Footer } = Layout;

const DashboardHeader = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { user, logout } = useAuth();
    const size = useWindowSize();
    const [drawerVisible, setDrawerVisible] = useState(false);

    const menuItems = [
        { key: "dashboard", label: "Dashboard", icon: <HomeOutlined />, roles: ["bayi", "firma"] },
        { key: "urunlerim", label: "Ürünlerim", icon: <AppstoreOutlined />, roles: ["bayi"] },
        { key: "paramlist", label: "İşlemler", icon: <WalletOutlined />, roles: ["firma"] },
        { key: "urunler", label: "Ürünler", icon: <AppstoreOutlined />, roles: ["firma"] },
        { key: "siparisler", label: "Siparişler", icon: <ShoppingCartOutlined />, roles: ["firma"] },
        { key: "siparislerim", label: "Siparişlerim", icon: <ShoppingCartOutlined />, roles: ["bayi"] },
        { key: "odemelerim", label: "Ödemelerim", icon: <UserOutlined />, roles: ["bayi"] },
        { key: "tahsilatlar", label: "Tahsilatlar", icon: <WalletOutlined />, roles: ["firma"] },
        { key: "bakiye", label: "Bakiye", icon: <WalletOutlined />, roles: ["firma"] },
        { key: "withcommission", label: "Komisyonlu Ödeme", icon: <WalletOutlined />, roles: ["bayi"] },
        { key: "odeme-yap", label: "Ödeme Yap", icon: <WalletOutlined />, roles: ["bayi"] },
        { key: "bayiler", label: "Bayiler", icon: <UserOutlined />, roles: ["firma"] },
    ];

    function useWindowSize() {
        const [windowSize, setWindowSize] = useState({
            width: undefined,
            height: undefined,
        });

        useEffect(() => {
            function handleResize() {
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            }

            window.addEventListener("resize", handleResize);
            handleResize();

            return () => window.removeEventListener("resize", handleResize);
        }, []);

        return windowSize;
    }

    const getMenuItems = () => {
        return menuItems
            .filter(item => item.roles.includes(user?.userType))
            .map(item => (
                <Menu.Item
                    key={item.key}
                    icon={item.icon}
                    onClick={() => {
                        navigate(`/${item.key}`);
                        setDrawerVisible(false);
                    }}
                    className={location.pathname.includes(item.key) ? "ant-menu-item-selected" : ""}
                >
                    {item.label}
                </Menu.Item>
            ));
    };

    return (
        <Layout>
            {size.width > 768 ? (
                <Header className="header">
                    <div className="logo-container" onClick={() => navigate('/dashboard')}>
                        <img src="/logo.png" alt="Logo" className="logo" />
                    </div>
                    <Menu
                        className="menu"
                        theme="dark"
                        mode="horizontal"
                        selectedKeys={[location.pathname.replace('/', '')]}
                    >
                        {getMenuItems()}
                    </Menu>
                    <Button className="logout-button" onClick={logout} icon={<LogoutOutlined />}>Çıkış Yap</Button>
                </Header>
            ) : (
                <>
                    <Header className="header-mobile">
                        <Button
                            className="menu-button"
                            icon={<MenuOutlined />}
                            onClick={() => setDrawerVisible(true)}
                        />
                        <div className="logo-container" onClick={() => navigate('/dashboard')}>
                            <img src="/logo.png" alt="Logo" className="logo" />
                        </div>
                        <Button className="logout-button" onClick={logout} icon={<LogoutOutlined />} />
                    </Header>
                    <Footer className="footer">
                        <Menu
                            mode="horizontal"
                            className="bottom-nav"
                            selectedKeys={[location.pathname.replace('/', '')]}
                        >
                            {menuItems.slice(0, 4).map(item => (  // Alt bara en sık kullanılan menü öğelerini ekliyoruz.
                                <Menu.Item
                                    key={item.key}
                                    icon={item.icon}
                                    onClick={() => navigate(`/${item.key}`)}
                                >
                                    {item.label}
                                </Menu.Item>
                            ))}
                        </Menu>
                    </Footer>
                    <Drawer
                        title="Menü"
                        placement="left"
                        closable={true}
                        onClose={() => setDrawerVisible(false)}
                        visible={drawerVisible}
                    >
                        <Menu mode="vertical">
                            {getMenuItems()}
                        </Menu>
                    </Drawer>
                </>
            )}
        </Layout>
    );
};

export default DashboardHeader;
