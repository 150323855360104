// PaymentFailure.js
import React from 'react';
import { useLocation } from 'react-router-dom';

const PaymentFailure = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const orderId = queryParams.get('order_id');
    const error = queryParams.get('error');

    return (
        <div>
            <h1>Ödeme Başarısız</h1>
            <p>Sipariş ID: {orderId}</p>
            <p>Hata Mesajı: {error}</p>
        </div>
    );
};

export default PaymentFailure;
