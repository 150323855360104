import React, { useState, useEffect } from 'react';
import { Button, Input, Select, message, Divider } from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { useAuth } from '../../context/AuthContext';

const { Option } = Select;

const ProductTypeManager = ({ form, productTypes, setProductTypes, selectedProductType, setSelectedProductType }) => {
    const { makeAuthenticatedRequest } = useAuth();
    const [newType, setNewType] = useState('');

    useEffect(() => {
        const fetchProductTypes = async () => {
            try {
                const response = await makeAuthenticatedRequest('/product-types', 'GET');
                setProductTypes(response.data);
            } catch (error) {
                message.error(`Ürün türleri yüklenirken hata oluştu: ${error.toString()}`);
            }
        };

        fetchProductTypes();
    }, [makeAuthenticatedRequest, setProductTypes]); // fetchProductTypes'ı useEffect bağımlılık dizisine ekledik

    const handleAddProductType = async () => {
        if (!newType.trim()) {
            message.error('Ürün türü adı boş olamaz.');
            return;
        }
        try {
            const response = await makeAuthenticatedRequest('/product-types', 'POST', { name: newType });
            setProductTypes([...productTypes, response.data]);
            setNewType('');
            message.success('Ürün türü başarıyla eklendi.');
        } catch (error) {
            message.error(`Ürün türü eklenirken hata oluştu: ${error.toString()}`);
        }
    };

    const handleDeleteProductType = async (typeId, event) => {
        event.stopPropagation();
        try {
            await makeAuthenticatedRequest(`/product-types/${typeId}`, 'DELETE');
            setProductTypes(productTypes.filter(type => type._id !== typeId));
            message.success('Ürün türü başarıyla silindi.');
        } catch (error) {
            message.error(`Ürün türü silinirken hata oluştu: ${error.toString()}`);
        }
    };
    const onTypeChange = (value) => {
        setSelectedProductType(value);
        form.setFieldsValue({ productType: value });
    };
    return (
        <Select
            style={{ width: '100%' }}
            placeholder="Ürün türünü seçin veya ekleyin"
            value={selectedProductType}
            onChange={onTypeChange}
            dropdownRender={(menu) => (
                <>
                    {menu}
                    <Divider style={{ margin: '8px 0' }} />
                    <div style={{ display: 'flex', alignItems: 'center', padding: '0 8px 4px' }}>
                        <Input value={newType} onChange={(e) => setNewType(e.target.value)} />
                        <Button type="text" icon={<PlusOutlined />} onClick={handleAddProductType}>
                            Ekle
                        </Button>
                    </div>
                </>
            )}
        >
            {productTypes.map((type) => (
                <Option key={type._id} value={type._id}>
                    {type.name}
                    <CloseOutlined onClick={(event) => handleDeleteProductType(type._id, event)} style={{ float: 'right', display: 'flex', alignItems: 'center' }} />
                </Option>
            ))}
        </Select>
    );
};

export default ProductTypeManager;
