import React, {useState, useEffect} from 'react';
import {Table, Button, Select, InputNumber, List, Space} from 'antd';
import {useAuth} from '../../context/AuthContext';
import {MinusOutlined} from '@ant-design/icons';

const DealerProducts = () => {
    const [dealerProducts, setDealerProducts] = useState([]);
    const [generalProducts, setGeneralProducts] = useState([]);
    const [orderList, setOrderList] = useState([]);
    const {makeAuthenticatedRequest, user} = useAuth();

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const dealerId = user.dealerId;
                const {data: dealerData} = await makeAuthenticatedRequest(`/products?assignedDealers=${dealerId}`, 'GET');
                setDealerProducts(dealerData);
            } catch (error) {
                console.error('Bayiye özel ürünler alınırken hata oluştu:', error);
            }

            try {
                const {data: generalData} = await makeAuthenticatedRequest('/products', 'GET');
                setGeneralProducts(generalData);
            } catch (error) {
                console.error('Genel ürünler alınırken hata oluştu:', error);
            }
        };

        fetchProducts();
    }, [makeAuthenticatedRequest, user]);

    const addToOrder = (product) => {
        setOrderList([...orderList, {...product, quantity: 1, unit: 'adet'}]);
    };

    const removeFromOrder = (index) => {
        const newOrderList = [...orderList];
        newOrderList.splice(index, 1);
        setOrderList(newOrderList);
    };

    const updateOrderItem = (index, updatedItem) => {
        const newOrderList = [...orderList];
        newOrderList[index] = updatedItem;
        setOrderList(newOrderList);
    };

    const columns = [
        {
            title: 'İsim',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Tür',
            dataIndex: 'productType',
            key: 'productType',
            render: (text, record) => record.productType ? record.productType.name : 'N/A',
        },
        {
            title: 'Ekle',
            key: 'add',
            render: (text, record) => (
                <Button style={{backgroundColor: 'green', color: 'white'}}
                        onClick={() => addToOrder(record)}>+</Button>),
        },
    ];

    return (
        <div>
            {dealerProducts.length > 0 && (
                <>
                    <h2>Bana Özel Ürünler</h2>
                    <Table
                        dataSource={dealerProducts}
                        columns={columns}
                        rowKey="_id"
                        pagination={{pageSize: 5}}
                    />
                </>
            )}
            <h2>Genel Ürünler</h2>
            <Table
                dataSource={generalProducts}
                columns={columns}
                rowKey="_id"
                pagination={{pageSize: 5}}
            />
            <h2>Sipariş Listesi</h2>
            <List
                itemLayout="horizontal"
                dataSource={orderList}
                renderItem={(item, index) => (
                    <List.Item style={{width: '100%'}}>
                        <List.Item.Meta
                            style={{width: '100%'}}
                            title={item.name}
                            description={
                                <Space style={{width: '100%', justifyContent: 'space-between'}}>
                                    <Space>
                                        <Select defaultValue="adet"
                                                onChange={(unit) => updateOrderItem(index, {...item, unit})}>
                                            <Select.Option value="adet">Adet</Select.Option>
                                            <Select.Option value="ton">Ton</Select.Option>
                                        </Select>
                                        <InputNumber min={1} defaultValue={1}
                                                     onChange={(quantity) => updateOrderItem(index, {
                                                         ...item,
                                                         quantity
                                                     })}/>
                                    </Space>
                                    <Button type="danger" icon={<MinusOutlined style={{color: 'white'}}/>}
                                            style={{backgroundColor: 'red'}}
                                            onClick={() => removeFromOrder(index)}/>
                                </Space>
                            }
                        />
                    </List.Item>
                )}
            />
            <Button type="primary" onClick={() => console.log('Sipariş oluştur:', orderList)}>
                Sipariş Oluştur
            </Button>
        </div>
    );
};

export default DealerProducts;