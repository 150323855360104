import {Row, Col, Card, Statistic, Table, message} from 'antd';
import {BankOutlined, CreditCardOutlined, ShoppingCartOutlined} from '@ant-design/icons';
import moment from 'moment';
import {useEffect, useState} from "react";
import {useAuth} from "../../context/AuthContext";

const FirmaDashboardPage = () => {
    const [totalDebt, setTotalDebt] = useState(0);
    const [totalCollection, setTotalCollection] = useState(0);

    const { makeAuthenticatedRequest } = useAuth();
    useEffect(() => {
        const fetchTotalDebt = async () => {
            try {
                const response = await makeAuthenticatedRequest('/debts/all', 'GET');
                const totalDebt = response.data.reduce((total, debt) => total + debt.totalDebt, 0);
                setTotalDebt(totalDebt);
            } catch (error) {
                message.error('Toplam borç yüklenirken bir hata oluştu: ' + error.toString());
            }
        };

        fetchTotalDebt();
    }, [makeAuthenticatedRequest]);

    useEffect(() => {
        const fetchTotalCollection = async () => {
            try {
                const response = await makeAuthenticatedRequest('/param/receipts', 'GET');
                const totalCollection = response.reduce((total, receipt) => total + receipt.tahsilatTutari, 0);
                setTotalCollection(totalCollection);
            } catch (error) {
                message.error('Toplam tahsilat yüklenirken bir hata oluştu: ' + error.toString());
            }
        };

        fetchTotalCollection();
    }, [makeAuthenticatedRequest]);

    const collectionsColumns = [
        {
            title: 'Tarih',
            dataIndex: 'date',
            key: 'date',
            render: text => moment(text).format('YYYY-MM-DD'),
        },
        {
            title: 'Miktar',
            dataIndex: 'amount',
            key: 'amount',
        },
    ];

    const ordersColumns = [
        {
            title: 'Sipariş ID',
            dataIndex: 'orderId',
            key: 'orderId',
        },
        {
            title: 'Miktar',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Durum',
            dataIndex: 'status',
            key: 'status',
        },
    ];

    return (
        <div>
            <Row gutter={16}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Card>
                        <Statistic
                            title="Borçlar"
                            precision={2}
                            value={totalDebt}
                            valueStyle={{ color: '#cf1322' }}
                            prefix={<BankOutlined />}
                        />
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Card>
                        <Statistic
                            title="Toplam Tahsilat"
                            precision={2}
                            value={totalCollection}
                            valueStyle={{ color: '#3f8600' }}
                            prefix={<CreditCardOutlined />}
                        />
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Card>
                        <Statistic
                            title="İşlenen Siparişler"
                            prefix={<ShoppingCartOutlined />}
                        />
                    </Card>
                </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: '20px' }}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Card title="Son Tahsilatlar" >
                        <Table columns={collectionsColumns} pagination={false} />
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Card title="Son Siparişler">
                        <Table columns={ordersColumns}  pagination={false} />
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default FirmaDashboardPage;