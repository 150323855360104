import React, { useState, useEffect } from 'react';
import { Button, Input, Select, message, Divider } from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { useAuth } from '../../context/AuthContext';

const { Option } = Select;

const WireDiameterOptionsManager = ({ form, wireDiameters, setWireDiameters, selectedWireDiameter, setSelectedWireDiameter }) => {
    const { makeAuthenticatedRequest } = useAuth();
    const [newWireDiameter, setNewWireDiameter] = useState('');

    useEffect(() => {
        const fetchWireDiameters = async () => {
            try {
                const response = await makeAuthenticatedRequest('/wire-diameter-options', 'GET');
                setWireDiameters(response.data);
            } catch (error) {
                message.error(`Tel çapı seçenekleri yüklenirken bir hata oluştu: ${error.toString()}`);
            }
        };

        fetchWireDiameters();
    }, [makeAuthenticatedRequest, setWireDiameters]);

    const handleAddWireDiameter = async () => {
        if (!newWireDiameter.trim()) {
            message.error('Tel çapı adı boş olamaz.');
            return;
        }
        try {
            const response = await makeAuthenticatedRequest('/wire-diameter-options', 'POST', { value: newWireDiameter });
            setWireDiameters([...wireDiameters, response.data]);
            setNewWireDiameter('');
            message.success('Tel çapı başarıyla eklendi.');
        } catch (error) {
            message.error(`Tel çapı eklenirken bir hata oluştu: ${error.toString()}`);
        }
    };

    const handleDeleteWireDiameter = async (wireDiameterId) => {
        try {
            await makeAuthenticatedRequest(`/wire-diameter-options/${wireDiameterId}`, 'DELETE');
            setWireDiameters(wireDiameters.filter(wireDiameter => wireDiameter._id !== wireDiameterId));
            message.success('Tel çapı başarıyla silindi.');
        } catch (error) {
            message.error(`Tel çapı silinirken bir hata oluştu: ${error.toString()}`);
        }
    };

    const onWireDiameterChange = (value) => {
        setSelectedWireDiameter(value);
        form.setFieldsValue({ wireDiameter: value }); // Formu güncelle
    };

    return (
        <Select
            style={{ width: '100%' }}
            placeholder="Tel çapı seçin veya ekleyin"
            value={selectedWireDiameter}
            onChange={onWireDiameterChange}
            dropdownRender={(menu) => (
                <>
                    {menu}
                    <Divider style={{ margin: '8px 0' }} />
                    <div style={{ display: 'flex', alignItems: 'center', padding: '0 8px 4px' }}>
                        <Input value={newWireDiameter} onChange={(e) => setNewWireDiameter(e.target.value)} />
                        <Button type="text" icon={<PlusOutlined />} onClick={handleAddWireDiameter}>
                            Ekle
                        </Button>
                    </div>
                </>
            )}
        >
            {wireDiameters.map((wireDiameter) => (
                <Option key={wireDiameter._id} value={wireDiameter._id}>
                    {wireDiameter.value}
                    <CloseOutlined onClick={() => handleDeleteWireDiameter(wireDiameter._id)} style={{ float: 'right', display: 'flex', alignItems: 'center' }} />
                </Option>
            ))}
        </Select>
    );
};

export default WireDiameterOptionsManager;
