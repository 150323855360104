import React, {createContext, useContext, useState, useEffect} from 'react';
import axios from '../axios'; // Ensure this points to your configured axios instance with baseURL set
import {useNavigate} from 'react-router-dom';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({children}) => {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const verifyToken = async (token) => {
            try {
                const response = await axios.get('/auth/validateToken', {
                    headers: {Authorization: `Bearer ${token}`},
                });
                setUser({
                    ...response.data.data,
                    token,
                });
            } catch (error) {
                console.error('Error verifying token:', error);
                localStorage.removeItem('token');
                navigate('/login');
            }
        };

        const token = localStorage.getItem('token');
        if (token) {
            verifyToken(token);
        }
    }, [navigate]);
    const login = async (email, password) => {
        try {
            const response = await axios.post('/auth/login', {email, password});
            localStorage.setItem('token', response.data.token);
            const user = {
                ...response.data.data, // Assuming the backend's response structure
                token: response.data.token,
                userType: response.data.data.userType, // Add this line
            };
            setUser(user);
            navigate('/');
            return user; // Return the user
        } catch (error) {
            console.error('Login error:', error);
            throw error;
        }
    };

    const logout = () => {
        localStorage.removeItem('token');
        setUser(null);
        navigate('/login');
    };
    const makeAuthenticatedRequest = async (url, method, data) => {
        const token = localStorage.getItem('token');

        if (!token) {
            console.error('Hata: Yerel depolamada belirteç bulunamadı.');
            return;
        }

        try {
            const response = await axios({
                method,
                url,
                data,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            console.error("Error making authenticated request:", error);

            if (error.response) {
                console.error('Response data:', error.response.data);
                console.error('Response status:', error.response.status);
                console.error('Response headers:', error.response.headers);
            } else if (error.request) {
                console.error('Request data:', error.request);
            } else {
                console.error('Error message:', error.message);
            }

            throw error;
        }
    };


    return (
        <AuthContext.Provider value={{user, login, logout, makeAuthenticatedRequest}}>
            {children}
        </AuthContext.Provider>
    );
};
