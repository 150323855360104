import React, {useEffect, useState} from 'react';
import {Table, Button, message} from 'antd';
import {useAuth} from '../../context/AuthContext';

const MyPaymentsRecordPage = () => {
    const [receipts, setReceipts] = useState([]);
    const {makeAuthenticatedRequest} = useAuth();

    useEffect(() => {
        const fetchReceipts = async () => {
            try {
                const response = await makeAuthenticatedRequest('/param/receipts', 'GET');
                if (response.length === 0) {
                    message.info('Hiç dekont bulunamadı.');
                }
                setReceipts(response.map(item => ({
                    dekontID: item.dekontID,
                    islemTarihi: item.islemTarihi,
                    odemeTutari: item.odemeTutari,
                    tahsilatTutari: item.tahsilatTutari,
                })));
            } catch (error) {
                console.error('Dekontlar yüklenirken bir hata oluştu:', error); // Hata detaylarını logla
                message.error('Dekontlar yüklenirken bir hata oluştu.');
            }
        };
        fetchReceipts();
    }, [makeAuthenticatedRequest]);

    const downloadReceipt = (dekontID) => {
        const url = `https://sumerlibackend-ens1.onrender.com/api/param/download-receipt?dekont_id=${dekontID}`;
        window.open(url, '_blank');
    };

    const columns = [
        {
            title: 'Dekont ID',
            dataIndex: 'dekontID',
            key: 'dekontID',
        },
        {
            title: 'Ödeme Zamanı',
            dataIndex: 'islemTarihi',
            key: 'islemTarihi',
            render: (text) => new Date(text).toLocaleDateString('tr-TR', {
                timeZone: 'Europe/Istanbul',
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            }),
        },
        {
            title: 'Ödeme Miktarı',
            dataIndex: 'odemeTutari',
            key: 'odemeTutari',
        },
        {
            title: 'Tahsilat Tutarı',
            dataIndex: 'tahsilatTutari',
            key: 'tahsilatTutari',
        },
        {
            title: 'Dekont',
            key: 'action',
            render: (text, record) => (
                <Button onClick={() => downloadReceipt(record.dekontID)}>Dekont İndir</Button>
            ),
        },
    ];

    return (
        <Table columns={columns} dataSource={receipts} rowKey="dekontID"/>
    );
};

export default MyPaymentsRecordPage;