import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const PaymentCallback = () => {
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [receiptData, setReceiptData] = useState(null);

    useEffect(() => {
        const fetchReceiptData = async () => {
            try {
                const queryParams = new URLSearchParams(location.search);
                const receiptId = queryParams.get('receiptId'); // Frontend'de URL'den ID'yi alıyoruz.

                if (receiptId) {
                    const response = await axios.get(`https://sumerlibackend-ens1.onrender.com/api/param/receipts/${receiptId}`);
                    setReceiptData(response.data);
                    setLoading(false);
                } else {
                    throw new Error('Receipt ID not found in URL');
                }
            } catch (error) {
                console.error('Error fetching receipt data:', error);
                setError('Ödeme bilgileri alınamadı. Lütfen tekrar deneyin.');
                setLoading(false);
            }
        };

        fetchReceiptData();
    }, [location]);

    if (loading) return <div>İşlem yapılıyor, lütfen bekleyin...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div>
            <h1>Ödeme Başarılı!</h1>
            <p><strong>Sipariş ID:</strong> {receiptData.siparisID}</p>
            <p><strong>Dekont ID:</strong> {receiptData.dekontID}</p>
            <p>Ödemeniz başarıyla tamamlanmıştır.</p>
            <a href={`https://sumerlibackend-ens1.onrender.com/api/param/download-receipt?dekont_id=${receiptData.dekontID}`}
               target="_blank" rel="noopener noreferrer">Dekontu İndir</a>
        </div>
    );
};

export default PaymentCallback;
